<template>
	<div id="adoptList">
		<page-delivery-order :is-shop="0"></page-delivery-order>
	</div>
</template>

<script>
import pageDeliveryOrder from '@/components/layout/adopt/page-delivery-order.vue'
export default{
	components:{
		pageDeliveryOrder
	},
}
</script>

<style lang="scss">

</style>
